import React from 'react';

import { Container } from '@uc-common/container';
import { ButtonTypeEnum } from '@uc-common/button';
import { Link } from '~/src/components/Link';
import { MediaPiplineScreenButton } from '~/src/components/forELearning/MediaPiplineScreen/styles/MediaPiplineScreen.styles';
import {
  SolutionsSubHeading,
  SolutionsDescription,
} from '~/src/components/forSolutions/styled-components';
import {
  OptimizingScreenSection,
  OptimizingScreenIntegrationsTitle,
  OptimizingScreenIntegrations,
} from './styles/OptimizingScreen.styles';

export const OptimizingScreen = () => (
  <OptimizingScreenSection data-navbar-theme="dark">
    <Container>
      <SolutionsSubHeading>We fit your stack and obsess over DX</SolutionsSubHeading>
      <SolutionsDescription>
        From bare metal to front-end libraries, we’ve built Uploadcare to work with your favorite
        tech stack and workflow. Save up to two years of engineering time not having to build the
        modern file, media and delivery capabilities your clients need.
      </SolutionsDescription>

      <MediaPiplineScreenButton
        forwardedAs={Link}
        to="/accounts/signup/"
        type={ButtonTypeEnum.PRIMARY}
        data-analytics="btn_signup_1"
      >
        Start building for free
      </MediaPiplineScreenButton>
    </Container>

    <OptimizingScreenIntegrationsTitle>
      Dozens of ready integrations
    </OptimizingScreenIntegrationsTitle>
    <OptimizingScreenIntegrations />
  </OptimizingScreenSection>
);
