export const data = [
  {
    title: 'Robust two-way infra ↑↓',
    text: 'Accelerate file uploads and media delivery in 180 locales with our heavily optimized multi-CDN.',
  },
  {
    title: 'Leading file APIs',
    text: 'Build custom software fast with modular APIs for uploads, image optimization, and file handling.',
  },
  {
    title: 'URL image editing ',
    text: 'Resize, edit and enhance images on the fly with the URL API, for a perfect look on every device.',
  },
  {
    title: 'Smart image compression',
    text: 'Automated content-aware optimization reduces image size by up to 80% without quality loss.',
  },
  {
    title: 'Multi-user auth',
    text: 'Set detailed access roles and media editing privileges for clients and your team members.',
  },
  {
    title: 'Built in computer vision',
    text: 'Content labeling, smart cropping, face detection, background removal and more – ready to go.',
  },
];
