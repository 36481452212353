import React from 'react';

import { Container } from '@uc-common/container';
import { BuzzLogoIdEnum, logos } from '@uc-common/buzz-logo';

import { SmallCard, Quote } from '~/src/components/forSolutions';
import { Section } from '../../forMain/Section';
import {
  ShippingScreenDescription,
  ShippingScreenCardsContainer,
  ShippingScreenSolutionsSubHeading,
} from './styles/ShippingScreen.styles';

export const ShippingScreen = () => (
  <Section data-navbar-theme="dark">
    <Container>
      <ShippingScreenSolutionsSubHeading>
        Ship client software that ticks all the file handling boxes
      </ShippingScreenSolutionsSubHeading>
      <ShippingScreenDescription>
        Uploadcare is a complete file and image pipeline engineered for performance and developer
        experience.
      </ShippingScreenDescription>
      <ShippingScreenCardsContainer>
        <SmallCard
          title="File uploading & management APIs"
          src="https://ucarecdn.com/0e928b99-e406-4f5e-9be4-edb80fe78ee5/"
        />
        <SmallCard
          title="Image CDN with best in class compression"
          src="https://ucarecdn.com/702acbbb-9886-4740-bd3a-d5a4e0981d68/"
        />
        <SmallCard
          title="Secure storage with bulk file & image operations"
          src="https://ucarecdn.com/8f9fcd00-0021-4e1f-ae4e-50c6b2a10aef/"
        />
        <SmallCard
          title="Easy integration, low maintenance"
          src="https://ucarecdn.com/bd44b641-dbd9-4208-a113-bc776a17ef2f/"
        />
      </ShippingScreenCardsContainer>
    </Container>

    <Quote
      logoSrc={logos[BuzzLogoIdEnum.MOZILLA].src}
      logoAlt="Mozilla logo"
      quoteText="It's the most painless, enjoyable flow out there. Awesome product. We all love Uploadcare dearly."
      avatarSrc="https://ucarecdn.com/6bf9903e-584d-459c-b621-819a5bf4910a/-/scale_crop/88x88/center/-/format/auto/-/quality/normal/"
      avatarAlt="Chris Van Wiemeersch | UX Engineer"
    />
  </Section>
);
