import React from 'react';

import { SiteLayout } from '../../components/Layout/SiteLayout';
import {
  FirstScreen,
  ShippingScreen,
  ServicesScreen,
  OptimizingScreen,
  PlatformFeaturesScreen,
  LastScreen,
} from '../../components/forDevShops';

export const frontmatter = {
  title: 'Uploadcare for software agencies',
  description:
    'Save months of engineering time on building, maintaining and optimizing file upload & delivery solutions for your web development projects.',
};

const Devshops = ({ location }) => (
  <SiteLayout meta={{ ...frontmatter }} pathName={location.pathname} isDark>
    <FirstScreen />
    <ShippingScreen />
    <ServicesScreen />
    <OptimizingScreen />
    <PlatformFeaturesScreen />
    <LastScreen />
  </SiteLayout>
);

export default Devshops;
