export const SERVICES = [
  {
    superHeading: 'File uploader & API',
    heading: 'Add upload capabilities to client projects',
    paragraph:
      'Customizable, responsive and built on a rock-solid infrastructure,' +
      ' our HTML5 file uploader loads fast and supports any file, size,' +
      ' and upload source, including Drive, Facebook, Dropbox and 12 more.',
    options: [
      'Responsive & easy to style',
      'Built-in image editor',
      'MIME type validation',
      'Multipart uploads (5TB/file)',
    ],
    imageUuid: '99e8696c-cd6e-48c1-9f72-981d3d16f792',
    imageOptions: { quality: 'smart', format: 'auto' },
    imageAlt: 'File uploader & API',
  },
  {
    superHeading: 'Cloud storege & file API',
    heading: 'Handle client files safely and with minimal effort',
    paragraph:
      'Manage multiple clients’ media from one dashboard, or give' +
      ' them their own access credentials. Build custom and white-label' +
      ' file management flows with a flexible API.',
    options: [
      'Secure storage & anti-malware',
      'Smart image optimizations',
      'Bulk file conversions',
      'File analysis & data insights',
    ],
    imageUuid: '4efe617d-ffd3-41a7-ba9f-7d83aa8e2c18',
    imageOptions: { quality: 'smart', format: 'auto' },
    imageAlt: 'Cloud storege & file API',
  },
  {
    superHeading: 'Multi-CDN & URL API',
    heading: 'Deliver lighter images, smarter & faster',
    paragraph:
      'Uploadcare CDN serves device- and content-aware images' +
      ' with up to 86% size compression. Automate responsive design' +
      ' and access powerful image editing in bulk or on the fly with' +
      ' an intuitive URL API.',
    options: [
      '325,000 points of presence',
      '350 Bn daily requests served',
      'Best in class compression',
      '99.99% guaranteed uptime',
    ],
    imageUuid: '1fce7279-e8a2-4840-9548-9df4dd50dfaf',
    imageOptions: { quality: 'smart', format: 'auto' },
    imageAlt: 'Multi-CDN & URL API',
  },
];
