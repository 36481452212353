import styled, { css } from 'styled-components';
import { Container } from '@uc-common/container';
import { Section } from '../../../forMain/Section';
import { CTAButton } from '../../../forMain/Typography';

export const LastScreenSection = styled(Section)(
  ({ theme: { tablet, laptop } }) => css`
    padding-bottom: 120px;
    padding-top: 55px;

    ${tablet()} {
      padding-bottom: 140px;
      padding-top: 70px;
    }

    ${laptop()} {
      padding-bottom: 170px;
    }
  `
);

export const LastScreenContainer = styled(Container)(
  ({ theme: { laptop } }) => css`
    &:not(:first-child) {
      margin-top: 100px;

      ${laptop()} {
        margin-top: 140px;
      }
    }
  `
);

export const LastScreenButton = styled(CTAButton)`
  margin-top: 30px;
  max-width: 300px;
  width: 100%;
  font-size: 16px;
`;
