import React from 'react';

import { ButtonTypeEnum } from '@uc-common/button';

import { Clients } from '~/src/components/Clients';
import { Link } from '../../Link';
import {
  FirstScreenGradientSuperHeading,
  Heading,
  StyledContainer,
  StyledSection,
  StyledSubText,
  FirstScreenButton,
  FirstScreenDiscountsBanner,
  FirstScreenButtonsContainer,
} from './styles/FirstScreen.styles';

export const FirstScreen = ({ btnSignUpProps = {} }) => (
  <StyledSection>
    <StyledContainer>
      <FirstScreenGradientSuperHeading>
        Uploadcare for software agencies
      </FirstScreenGradientSuperHeading>
      <Heading>Image CDN and File Pipeline for Development Agencies</Heading>

      <StyledSubText>
        Save months of engineering time on building, maintaining and optimizing file upload &
        delivery solutions for your web development agencies.
      </StyledSubText>

      <FirstScreenButtonsContainer>
        <FirstScreenButton
          forwardedAs={Link}
          to="/accounts/signup/"
          type={ButtonTypeEnum.PRIMARY}
          data-analytics="btn_signup_0"
          {...btnSignUpProps}
        >
          Get started for free
        </FirstScreenButton>

        <FirstScreenButton
          forwardedAs={Link}
          to="/partners/"
          type={ButtonTypeEnum.TRANSPARENT}
          data-analytics="btn_first-screen_partners"
          ghost
        >
          Partner Program
        </FirstScreenButton>
      </FirstScreenButtonsContainer>

      <FirstScreenDiscountsBanner>
        Special discounts for development agencies and solution partners
      </FirstScreenDiscountsBanner>

      <Clients />
    </StyledContainer>
  </StyledSection>
);
