import styled, { css } from 'styled-components';
import { Container } from '@uc-common/container';
import { SubText, CTAButton } from '../../../forMain/Typography';
import { Section } from '../../../forMain/Section';
import diamondGradient from '../../assets/diamondGradient.svg';
import { GradientSuperHeading } from '../../../Typography';

export const FirstScreenGradientSuperHeading = styled(GradientSuperHeading)`
  background: linear-gradient(
    45deg,
    rgba(255, 142, 76, 1) 0%,
    rgba(255, 171, 111, 1) 26%,
    rgba(255, 183, 116, 1) 44%,
    rgba(255, 217, 144, 1) 74%,
    rgba(255, 226, 123, 1) 100%
  );
`;

export const Heading = styled.h1(
  ({ theme: { tablet, laptop, desktop } }) => css`
    font-size: 30px;
    line-height: 1.2;
    font-weight: 700;
    margin-bottom: 19px;
    color: #ffffff;
    display: inline-flex;
    flex-direction: column;
    max-width: 450px;

    ${tablet()} {
      font-size: 56px;
      margin-bottom: 30px;
      max-width: 770px;
    }

    ${laptop()} {
      font-size: 60px;
      max-width: 820px;
    }

    ${desktop()} {
      font-size: 70px;
      max-width: 960px;
    }
  `
);

export const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const StyledSection = styled(Section)(
  ({ theme: { tablet, laptop, desktop } }) => css`
    padding-top: 90px;
    background: url(${diamondGradient}) no-repeat center;
    background-size: 800px auto;

    ${tablet()} {
      padding-top: 173px;
      background-position-y: 10px;
      background-size: auto auto;
    }

    ${laptop()} {
      padding-top: 160px;
      background-position-y: 15px;
    }

    ${desktop()} {
      padding-top: 190px;
      background-position-y: 0;
      background-size: 1200px auto;
    }
  `
);

export const StyledSubText = styled(SubText)(
  ({ theme: { laptop } }) => css`
    max-width: 640px;

    ${laptop()} {
      max-width: 830px;
    }
  `
);

export const FirstScreenButton = styled(CTAButton)`
  margin-top: 0;
  max-width: 300px;
  width: 100%;
  font-size: 16px;
`;

export const FirstScreenDiscountsBanner = styled.p(
  ({ theme: { laptop } }) => css`
    margin-top: 20px;
    font-size: 13px;
    color: #bbc4d2;
    display: flex;

    &::before {
      background-image: url('https://ucarecdn.com/f9c6fc13-8f69-4c38-a998-defb6aca836a/sparkles_2728.png');
      background-size: 25px 25px;
      display: inline-block;
      background-repeat: no-repeat;
      height: 25px;
      width: 25px;
      min-width: 25px;
      content: '';
      margin-right: 8px;
    }

    ${laptop()} {
      font-size: 15px;
    }
  `
);

export const FirstScreenButtonsContainer = styled.div(
  ({ theme: { tablet } }) => css`
    display: grid;
    grid-template-columns: 198px;
    grid-gap: 10px;
    margin-top: 30px;

    ${tablet()} {
      grid-template-columns: 198px 198px;
    }
  `
);
