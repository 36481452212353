import React from 'react';

import { Container } from '@uc-common/container';

import { Section } from '~/src/components/forMain/Section';
import { Service } from '~/src/components/forSolutions';
import { Advantages, AdvantagesLogosEnum } from '~/src/components/forSolutions/Advantages';
import { ServicesContainer } from './styles/ServicesScreen.styles';
import { SERVICES } from './data';

export const ServicesScreen = () => (
  <Section data-navbar-theme="dark">
    <Container>
      <ServicesContainer>
        {SERVICES.map(
          (
            { superHeading, heading, paragraph, options = [], imageUuid, imageAlt, imageOptions },
            index
          ) => (
            <Service
              key={heading}
              index={index + 1}
              superHeading={superHeading}
              heading={heading}
              paragraph={paragraph}
              options={options}
              imageUuid={imageUuid}
              imageAlt={imageAlt}
              imageOptions={imageOptions}
            />
          )
        )}
      </ServicesContainer>

      <Advantages
        advantage="Full privacy and data protection compliance"
        advantageProtocol={[
          AdvantagesLogosEnum.GDPR,
          AdvantagesLogosEnum.HIPAA,
          AdvantagesLogosEnum.DMCA,
          AdvantagesLogosEnum.SOC2,
        ]}
      />
    </Container>
  </Section>
);
