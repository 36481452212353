import styled, { css } from 'styled-components';
import { Integrations } from '~/src/components/forMain/Uploading/Integrations';
import { Section } from '~/src/components/forMain/Section';

export const OptimizingScreenSection = styled(Section)`
  overflow: hidden;
`;

export const OptimizingScreenIntegrationsTitle = styled.h5(
  ({ theme: { tablet, laptop } }) => css`
    display: block;
    font-weight: 500;
    font-size: 10px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin: 50px auto 15px;
    color: #838f9a;

    ${tablet()} {
      font-size: 12px;
      margin-top: 70px;
    }

    ${laptop()} {
      font-size: 14px;
      margin-top: 90px;
    }
  `
);

export const OptimizingScreenIntegrations = styled(Integrations)(
  ({ theme: { tablet } }) => css`
    margin-top: 15px;
    padding-bottom: 30px;

    ${tablet()} {
      padding-bottom: 60px;
      margin-top: 35px;
    }
  `
);

export const OptimizingScreenQuoteSection = styled.div(
  ({ theme: { laptop } }) => css`
    width: 100%;
    padding: 70px 0;
    background-color: #08080a;
    margin-top: 70px;

    ${laptop()} {
      margin-top: 90px;
    }
  `
);
