import React from 'react';

import { ButtonTypeEnum } from '@uc-common/button';

import {
  SolutionsSubHeading,
  SolutionsDescription,
} from '~/src/components/forSolutions/styled-components';
import { Link } from '../../Link';
import { PlanCardFree } from '../../PlanCardFree';
import {
  LastScreenSection,
  LastScreenButton,
  LastScreenContainer,
} from './styles/LastScreen.styles';

export const LastScreen = () => (
  <LastScreenSection data-navbar-theme="dark">
    <LastScreenContainer>
      <PlanCardFree btnSignUpProps={{ 'data-analytics': 'btn_signup_2' }} />
    </LastScreenContainer>

    <LastScreenContainer>
      <SolutionsSubHeading>Let’s make things official?</SolutionsSubHeading>
      <SolutionsDescription>
        Earn revenue from Uploadcare as a Solution, Technology or Affiliate partner. Or join makers
        like remove.bg who launched their core product on the Uploadcare platform.
      </SolutionsDescription>

      <LastScreenButton
        forwardedAs={Link}
        to="/partners/"
        type={ButtonTypeEnum.TRANSPARENT}
        data-analytics="btn_last-screen_partners"
        ghost
      >
        Partner with Uploadcare
      </LastScreenButton>
    </LastScreenContainer>
  </LastScreenSection>
);
