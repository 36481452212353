import React from 'react';
import { Container } from '@uc-common/container';
import { Quote, FeatureCard } from '~/src/components/forSolutions';
import { SolutionsSubHeading } from '~/src/components/forSolutions/styled-components';
import ssgLogo from '../assets/SsgLogo.svg';
import {
  PlatformFeaturesScreenSection,
  PlatformFeaturesScreenGradientSuperHeading,
  PlatformFeaturesScreenFeaturesGrid,
  PlatformFeaturesScreenApiExample,
} from './styles/PlatformFeaturesScreen.styles';
import { data } from './data';

export const PlatformFeaturesScreen = () => (
  <PlatformFeaturesScreenSection data-navbar-theme="dark">
    <PlatformFeaturesScreenApiExample />
    <Container>
      <PlatformFeaturesScreenGradientSuperHeading>
        Platform features
      </PlatformFeaturesScreenGradientSuperHeading>
      <SolutionsSubHeading>
        Tap into a complete file and media handling pipeline
      </SolutionsSubHeading>

      <PlatformFeaturesScreenFeaturesGrid>
        {data.map(({ title, text }) => (
          <FeatureCard key={title} title={title} text={text} />
        ))}
      </PlatformFeaturesScreenFeaturesGrid>
    </Container>

    <Quote
      logoSrc={ssgLogo}
      logoAlt="Special Situations Group logo"
      quoteText="The 'return on investment' of Uploadcare is
      self-evident from day one, and that's just from the time savings.
      I can think of no reason why anyone should reinvent the wheel or even
      attempt to roll out their own solution from scratch. Uploadcare has
      brought file uploading, processing, and delivery to perfection."
      avatarSrc="https://ucarecdn.com/f5bcbf71-40ae-4d88-9d13-4257699b4d7e/-/scale_crop/88x88/center/-/format/auto/-/quality/normal/"
      avatarAlt="Terence Hong | CEO"
    />
  </PlatformFeaturesScreenSection>
);
