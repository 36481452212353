import styled, { css } from 'styled-components';
import { GradientSuperHeading } from '~/src/components/Typography';
import { Section } from '~/src/components/forMain/Section';
import { ApiExample } from '~/src/components/forMain/Platform/ApiExample';

export const PlatformFeaturesScreenSection = styled(Section)(
  ({ theme: { tablet } }) => css`
    padding-top: 0;

    ${tablet()} {
      padding-top: 0;
    }
  `
);

export const PlatformFeaturesScreenGradientSuperHeading = styled(GradientSuperHeading)`
  align-self: center;
  display: block;

  background: radial-gradient(141.01% 172.5% at 0% 0%, #b951be 0%, #9365fd 84.45%);
`;

export const PlatformFeaturesScreenFeaturesGrid = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    display: grid;
    grid-gap: 20px;
    margin-top: 50px;

    ${tablet()} {
      grid-template-columns: 1fr 1fr;
      margin-top: 70px;
    }

    ${laptop()} {
      grid-template-columns: repeat(3, 1fr);
    }
  `
);

export const PlatformFeaturesScreenApiExample = styled(ApiExample)`
  margin-bottom: 20px;
`;
